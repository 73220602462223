<template>
  <autocomplete
      id="searchbox"
      class="search__box"
      url="/api/v1/search"
      anchor="title"
      placeholder="Search for model"
      :min=3
      :on-select="itemSelected"
      :process="processJsonData"
  >
  </autocomplete>
</template>
<script>
import Autocomplete from '../vendor/vue2-autocomplete.min';
import {TimelineLite, Power4} from 'gsap'

export default {
  name: "SearchAutocomplete",
  components: {Autocomplete},
  computed: {
    html: function () {
      return document.documentElement
    },
    searchEl: function () {
      return document.querySelector('.js-search')
    },
    searchInput: function () {
      return document.querySelector('#searchbox')
    },
    searchToggle: function () {
      return document.querySelector('.js-search-toggle')
    },
    searchToggleClose: function () {
      return document.querySelector('.js-search-toggle-close')
    },
    searchWrapper: function () {
      return document.querySelector('.js-search-wrapper')
    },
    tl: function () {
      return new TimelineLite()
    },
  },
  methods: {
    itemSelected(data) {
      window.location.href = data.url;
    },
    processJsonData(json) {
      return json.data;
    },
    freezeVp(e) {
      e.preventDefault()
    },
    stopBodyScrolling(bool) {
      if (bool === true) {
        document.body.addEventListener('touchmove', this.freezeVp, false)
      } else {
        document.body.removeEventListener('touchmove', this.freezeVp, false)
      }
    },
    toggleSearchEls() {
      this.searchToggle.classList.toggle('active')
      this.searchToggle.classList.toggle('o-0')
      this.searchEl.classList.toggle('flex')
      this.searchEl.classList.toggle('justify-center')
      this.searchEl.classList.toggle('items-center')
      this.searchEl.classList.toggle('flex-column')
      this.searchEl.classList.toggle('active')
      this.html.classList.toggle('has-nav-overlay')
      this.searchInput.focus()
    },
    openSearch() {
      this.toggleSearchEls()
      this.tl.to(this.searchEl, 0.4, {opacity: 1, ease: Power4.easeOut})
          .fromTo(this.searchWrapper, 0.6,
              {x: '40px', opacity: 0, ease: Power4.easeOut},
              {x: 0, opacity: 1, ease: Power4.easeOut},
              '-=0.25')

      if (this.html.classList.contains('has-nav-overlay')) {
        this.stopBodyScrolling(true)
        document.body.style.overflow = 'hidden'
      } else {
        this.stopBodyScrolling(false)
        document.body.style.overflow = 'visible'
      }
    },
    closeSearch() {
      this.tl.to(this.searchWrapper, 0.6, {x: '40px', opacity: 0, ease: Power4.easeOut})
          .to(this.searchEl, 0.4, {opacity: 0, ease: Power4.easeOut})
          .call(() => {
            this.toggleSearchEls()
            if (this.html.classList.contains('has-nav-overlay')) {
              this.stopBodyScrolling(true)
              document.body.style.overflow = 'hidden'
            } else {
              this.stopBodyScrolling(false)
              document.body.style.overflow = 'visible'
            }
          }, null, null, null)
    },
  },

  mounted() {
    this.searchToggle.addEventListener('click', this.openSearch)
    this.searchToggleClose.addEventListener('click', this.closeSearch)
  },
};

</script>